import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  { path: "settings", name: "settings", redirect: "emailTemplates" },
  {
    path: "settings/email-templates",
    name: "emailTemplates",
    component: () => import("@/modules/hollymontt-admin/views/EmailTemplatesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "mailing-templates",
      title: "Plantillas de correo",
    },
  },
  {
    path: "settings/email-templates/:id",
    name: "emailTemplatesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "emailTemplates"],
      serviceName: "mailing-templates",
      title: "Plantilla de correo",
    },
  },
  {
    path: "settings/lazy-portfolios",
    name: "lazyPortfolios",
    component: () => import("@/modules/hollymontt-admin/views/LazyPortfoliosCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "lazy-portfolios",
      title: "Lazy Portfolios",
    },
  },
  {
    path: "settings/lazy-portfolios/:id",
    name: "lazyPortfoliosEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "lazyPortfolios"],
      serviceName: "lazy-portfolios",
      title: "Lazy Portfolios",
    },
  },
  {
    path: "settings/subscription-types",
    name: "subscriptionTypes",
    component: () => import("@/modules/hollymontt-admin/views/SubscriptionTypesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "subscription-types",
      title: "Tipos de suscripción",
    },
  },
  {
    path: "settings/subscription-types/:id",
    name: "subscriptionTypesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "subscriptionTypes"],
      serviceName: "subscription-types",
      title: "Tipos de suscripción",
    },
  },
];
